<template>
  <el-row>
    <el-row class="movie-list">
      <el-col v-for="(item, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
        <el-col style="padding-right: 7px; padding-left: 7px; padding-bottom: 7px">
          <div style="cursor: pointer" :title="item.title">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <router-link target="_blank" :to="`/mall/item?id=${item.itemId}`">
                <div class="imgs">
                  <el-image
                    lazy
                    fit="cover"
                    :src="item.picUrl"
                    class="coverImg"
                  />
                  <span style="position: absolute; top: 0; left: 0; color:red"/>
                </div>
              </router-link>
              <div style="padding: 14px">
                <router-link style="text-decoration-line: none" target="_blank" :to="`/mall/item?id=${item.itemId}`">
                  <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.title | ellipsis }}</span>
                </router-link>
              </div>
              <div style="padding: 14px">
                <span style="left: 0;margin-bottom: 0px;color: red;">
                  ¥{{item.price}}
                </span>
                <span style="right: 0;margin-bottom: 0px;color: black;">
                  {{item.monthSale}}
                </span>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import {getProducts} from "@/api/mall";

export default {
  name: 'Overview',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 15
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 5
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  created() {
    document.title = '我的商城'
    this.getData()
  },
  methods: {
    getData() {
      getProducts(1).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.coverImg {
  width: 100%;
  height: 90px;
  display: block;
}
</style>
